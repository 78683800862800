@import '../../styles/partials/fonts';
@import '../../styles/partials/colors';
@import '../../styles/partials/breakpoints';

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes wipeDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.greeting.animated {
  // animation: wipeDown 0.2s ease-in both;
  animation: fadeOut 0.3s ease-in both;
}

.greeting {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  color: #fff;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: fixed;
  padding: 0 15vw;

  .logo {
    position: absolute;
    top: 10vh;
    img {
      width: 20px;
    }
  }

  .greeting-logo {
    animation: fadeInOut 2s ease 1.5s both;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  .greeting-content {
    text-align: left;
    width: 70vw;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
    animation: fadeIn 2s ease 1.5s both;
    font-size: 5vmax;
    line-height: 6.5vmax;
    font-weight: 100;

    .greeting-text {
      color: $medium-gray;
      font-weight: 100;
    }
  }
}

@media only screen and (min-width: $tablet-portrait-dimensions) {
  .greeting {
    .logo {
      img {
        width: 30px;
      }
    }
    .greeting-content {
      width: 60vw;
    }
  }
}

.noscroll {
  overflow: hidden;
}
