@import './colors';

$font-size-5x-large: 6rem;
$font-size-4x-large: 5rem;
$font-size-xxx-large: 4rem;
$font-size-xx-large: 3rem;
$font-size-x-large: 2rem;
$font-size-large: 1.5rem;
$font-size-medium: 1.25rem;
$font-size-normal: 1rem;
$font-size-small: 0.75rem;

$heading-font-size: 6rem;

// Header Font Mixins

@mixin header-font-title {
  font-size: $font-size-normal;
  font-weight: 400;
  text-transform: uppercase;
}

@mixin header-role-font {
  letter-spacing: 0.6rem;
  font-size: $font-size-normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5rem;
}

@mixin header-menu-link-font {
  font-size: $font-size-small;
  text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-decoration: none;
}

// Hero Font mixins

@mixin home-hero-title-font--mobile {
  font-size: $font-size-normal;
  text-align: center;
  letter-spacing: 0.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

@mixin hero-title-font {
  font-size: $font-size-xx-large;
  font-weight: 300;
  color: $light-black;
  text-transform: uppercase;
}

@mixin hero-label-font {
  font-size: $font-size-small;
  color: $medium-gray;
  font-weight: 300;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}

// Description/Summary font mixins

@mixin description-font {
  font-size: $font-size-large;
  color: $medium-gray;
  line-height: 2.5rem;
}

@mixin summary-font {
  font-size: $font-size-large;
  color: $light-black;
  font-weight: 300;
  line-height: 2.5rem;
  line-height: 2rem;
}

// Home Font Mixins

@mixin experience-title-font {
  font-size: 180px;
  letter-spacing: 0.05em;
  color: $light-gray;
  font-weight: 400;
}

@mixin experience-title-font--mobile {
  font-size: $font-size-xxx-large;
  letter-spacing: 0.05em;
  color: $light-gray;
  font-weight: 400;
}

@mixin experience-title-font--tablet {
  font-size: $font-size-5x-large;
  letter-spacing: 0.05em;
  color: $light-gray;
  font-weight: 400;
}

@mixin experience-title-font--tablet-landscape {
  font-size: 120px;
  letter-spacing: 0.05em;
  color: $light-gray;
  font-weight: 400;
}

@mixin experience-title-font--desktop-small {
  font-size: 180px;
  letter-spacing: 0.05em;
  color: $light-gray;
  font-weight: 400;
}

@mixin experience-view-all-font {
  font-size: $font-size-x-large;
  text-decoration: none;
  color: black;
}

// Case Study Section Font Mixins

@mixin section-title-font {
  font-size: $font-size-x-large;
  color: $light-black;
  font-weight: 400;
}

@mixin section-role-font {
  color: $medium-gray;
  font-weight: 400;
  font-size: $font-size-medium;
}

@mixin section-awards-font {
  font-weight: 400;
}

@mixin section-view-font {
  font-size: $font-size-medium;
  text-decoration: none;
  color: $light-black;
}

// Case Study Font Mixins

@mixin case-study-section-heading-font {
  color: $light-gray;
  font-weight: 500;
  font-size: $font-size-xx-large;
  letter-spacing: 0.5rem;
}

@mixin case-study-section-heading-font--tablet {
  @include case-study-section-heading-font;
  font-size: $font-size-5x-large;
}

@mixin case-study-section-title-font {
  font-size: $font-size-xx-large;
  font-weight: 500;
}

@mixin case-study-section-text-font {
  font-size: $font-size-medium;
  line-height: 2.5rem;
}

@mixin case-study-summary-title-font {
  font-size: $font-size-xx-large;
  font-weight: 500;
}

@mixin case-study-summary-subtitle-font {
  margin-right: 25px;
  color: $medium-gray;
  font-weight: 400;
  font-size: $font-size-normal;
}

@mixin case-study-summary-text-font {
  font-size: $font-size-normal;
  line-height: 1.5rem;
}

@mixin case-study-summary-text-font--tablet {
  font-size: $font-size-medium;
  line-height: 2.5rem;
}

@mixin case-study-awards-text-font {
  font-weight: bold;
}

@mixin case-study-nav-link-font {
  font-size: $font-size-x-large;
  color: black;
  text-decoration: none;
}

@mixin case-study-gallery-text-font {
  font-size: $font-size-large;
}

// About Page Font Mixins

@mixin about-text-font {
  font-size: $font-size-normal;
  color: #fff;
  line-height: 1.5rem;
}

// Community Font Mixins

@mixin community-heading-font {
  font-size: 1.25rem;
  color: $medium-gray;
  letter-spacing: 0.05rem;
  line-height: 2rem;
}

// Footer Font Mixins

@mixin footer-contact-font {
  font-size: $font-size-xx-large;
  color: $light-black;
}

@mixin greeting-font {
  font-size: $font-size-x-large;
  line-height: 2.5rem;
  color: #fff;
}
