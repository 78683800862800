@font-face {
  font-family: 'Din Bold';
  src: url(./assets/fonts/D-DIN-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Din Italic';
  src: url(./assets/fonts/D-DIN-Italic.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Din Regular';
  src: url(./assets/fonts/D-DIN.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Din Condensed';
  src: url(./assets/fonts/D-DINCondensed.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Din Condensed Bold';
  src: url(./assets/fonts/D-DINCondensed-Bold.otf);
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Din regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

p {
  line-height: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;

    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInUp {
  animation: fadeInUp;
}

.fadeInLeft {
  animation: fadeInLeft;
}

.fadeInRight {
  animation: fadeInRight;
}

.fadeIn {
  animation: fadeIn;
}
