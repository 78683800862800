@import '../../styles/partials/breakpoints';
@import '../../styles/partials/fonts';
@import '../../styles/partials/colors';

.menu-link {
  @include header-menu-link-font;
  color: $menu-link-color;
  text-decoration: none;
  position: relative;
  margin-top: 50px;
  align-self: flex-start;
}

.inactive-menu-center {
  align-self: center;
}

.inactive-menu-start {
  align-self: flex-start;
}

.active-menu-link {
  color: #000;
  font-weight: bold;
  align-self: flex-end;
}

.active-menu-link-reverse {
  color: #fff;
}

.underline,
.reverse-underline {
  border-bottom: 1px solid #000;
  height: 5px;
  margin-left: -1px;
}

.reverse-underline {
  border-bottom: 1px solid #fff;
}

.cross-through,
.reverse-cross-through {
  border-right: 1px solid #000;
  width: 10px;
  height: 25px;
  position: absolute;
  top: -5px;
  left: 10px;
  transform: skew(-40deg);
}

.reverse-cross-through {
  border-right: 1px solid #fff;
}

@media screen and (min-width: $tablet-landscape-dimensions) {
  .menu-link {
    margin-top: 30px;
  }
}
