.error {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.75rem;
  font-size: 2rem;
}
