@import '../../../styles/partials/breakpoints';

.main-container {
  display: grid;
  grid-template-columns: 100%;

  &__center {
    overflow: hidden;
  }
}

@media screen and (min-width: $tablet-portrait-dimensions) {
  .main-container {
    grid-template-columns: 768px;
  }
}

@media screen and (min-width: $tablet-landscape-dimensions) {
  .main-container {
    grid-template-columns: 1024px;
  }
}

@media screen and (min-width: $desktop-small-dimensions) {
  .main-container {
    grid-template-columns: 1400px;
  }
}

@media screen and (min-width: $desktop-large-dimensions) {
  .main-container {
    grid-template-columns: 1920px;
  }
}
