@import '../../styles/partials/breakpoints';
@import '../../styles/partials/fonts';

.menu {
  display: grid;
  grid-template-columns: 25px 300px 375px 300px 25px;
  height: 100px;
  width: 100%;
}

.open-icon-container {
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
}

.open-icon {
  width: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 375px) {
  .open-icon {
    width: 25px;
    margin-top: 15px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 425px) {
  .open-icon {
    width: 30px;
    margin-top: 15px;
    margin-right: 15px;
  }
}

@media screen and (min-width: $tablet-portrait-dimensions) {
  header {
    width: 768px;
  }
  .open-icon {
    width: 35px;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media screen and (min-width: $tablet-landscape-dimensions) {
  header {
    width: 1280px;
  }
  .title {
    @include header-font-title;
  }
  .menu {
    grid-template-columns: 25px 200px 500px 300px 25px;
    height: 100px;
  }
}

@media screen and (min-width: $desktop-small-dimensions) {
  header {
    width: 1400px;
  }
  .menu {
    grid-template-columns: 25px 200px 1fr 300px 25px;
    height: 100px;
  }
}

@media screen and (min-width: $desktop-large-dimensions) {
  header {
    width: 1920px;
  }
  .menu {
    grid-template-columns: 25px 200px 1320px 300px 25px;
    height: 100px;
  }
}
