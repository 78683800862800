@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.75rem;
  font-size: 2rem;
  z-index: 200;
  background: white;
  overflow: hidden;
  opacity: 0;
  animation: appear 0s linear 1s forwards;

  svg {
    animation: pulse 2.5s ease-in-out infinite;
  }
}

.loader.nodelay {
  animation: appear 0s linear 0s forwards;
}

.loader.fade {
  transition: all 400ms ease-out;
}

.loader.background {
  background-color: #1e1e1e;
}
