@keyframes expanded {
  0% {
    transform: translate(0, -290px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes collapsed {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0, -290px);
    opacity: 0;
  }
}

.close-icon {
  width: 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 10px;
  cursor: pointer;
}

@media screen and (min-width: 375px) {
  .close-icon {
    width: 25px;
    margin-top: 15px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 425px) {
  .close-icon {
    width: 30px;
    margin-top: 15px;
    margin-right: 15px;
  }
}

@media screen and (min-width: 768px) {
  .close-icon {
    width: 35px;
    margin-top: 20px;
    margin-right: 20px;
  }
}

.mobile-menu {
  position: absolute;
  background: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 75px 50px 50px 50px;
  z-index: 100;
  width: 100%;
  transform: translate(0, -290px);
  transition: transform 0.4s ease-in-out;
}

.collapsed {
  transform: translate(0, -290px);
}

.expanded {
  transform: translate(0, 0);
}
